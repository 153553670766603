import React from 'react';
import { Grid, Typography, ListItem } from '@mui/material';
import BlackDot from './BlackDot';

function TwoColumnList() {
  return (
    <Grid container spacing={2} sx={{marginBottom:1.5}}>
      <Grid item xs={6}>
        <ListItem sx={{ paddingLeft: 2 }}>
          <Typography variant="body1">
           1️⃣ FI(Financial Information) Request-Response from FIU → AA
          </Typography>
        </ListItem>
        <ListItem sx={{ paddingLeft: 2 }}>
          <Typography variant="body1">
           2️⃣ FI Request-Response AA → FIP
          </Typography>
        </ListItem>
        <ListItem sx={{ paddingLeft: 2 }}>
          <Typography variant="body1">
            3️⃣ FI Status Notification from FIP → AA
          </Typography>
        </ListItem>
        <ListItem sx={{ paddingLeft: 2 }}>
          <Typography variant="body1">
            4️⃣ FI Fetch Request-Response from FIU → FIP
          </Typography>
        </ListItem>
        <ListItem sx={{ paddingLeft: 2 }}>
          <Typography variant="body1">
            5️⃣ FI Status Notification from AA → FIU
          </Typography>
        </ListItem>
        <ListItem sx={{ paddingLeft: 2 }}>
          <Typography variant="body1">
            6️⃣ FI Fetch Request-Response from FIU → AA
          </Typography>
        </ListItem>
      </Grid>
    </Grid>
  );
}

export default TwoColumnList;
