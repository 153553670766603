import React, { useEffect,useState} from "react";
import RapiDocReact from "../components/RapidDocReact";
import { Box ,Typography,Select, MenuItem,SelectChangeEvent} from "@mui/material";
import { useLocation, Link } from "react-router-dom";
import ConsentFlowImage from "../assets/Consent.png";
import DataFlowImage from "../assets/Data.png"
import BlackDot from "../components/BlackDot";
import HealthApiEventTable from '../components/HealthApiEventTable'
import ConsentApiEventTable from '../components/ConsentApiEventTable'
import DataApiEventTable from '../components/DataApiEventTable'
import HealthApiResponseTable from "../components/HealthApiResponseTable";
import HealthApiRequestTable from "../components/HealthApiRequestTable";
import SuccessScoreCalculationTable from "../components/SuccessScoreCalculationTable";
import SuccessScoreRequestTable from "../components/SuccessScoreRequestTable";
import SuccessScoreResponseTable from "../components/SuccessScoreResponseTable";
import DocNavigation from "../components/DocNavigation";
import { Button } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from "react-router-dom";

function navigateToSection(hash: string) {
    const element = document.getElementById(hash);
    if (element) {
        var headerOffset = 45;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.scrollY - headerOffset;
        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    }
}

const WealthScapePage: React.FC = React.memo(() => {
    const location = useLocation();    
    const [selectedUrl, setSelectedUrl] = useState("https://fastrack.stage.finvu.in/v3/api-docs");
    const navigate = useNavigate();

    const handleChange = (event: SelectChangeEvent<string>) => {
        setSelectedUrl(event.target.value as string);
    };

    
    useEffect(() => {
        if (location.hash.length === 0) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            return;
        }
        const hash = location.hash.slice(1);
        navigateToSection(hash);
    }, [location.hash]);

    return <>
        <Box sx={{
            paddingLeft: 4,
        }}>
            {/* <Typography 
                sx={{fontWeight:'bold',marginTop:2,
                    fontSize: { xs: "1.2rem", sm: "1.5rem" },
                    display: 'inline-block',
                    marginBottom: 1,
                    color: "#0b3452",
                    lineHeight: 1.5,}}
            >
                Fastrack
            </Typography> */}

            {/* <Box 
                sx={{ marginTop: 1,
                    fontWeight: 'bold',
                    fontSize: { xs: "1.2rem", sm: "1.5rem" },
                    display: 'inline-block',
                    marginBottom: 1,
                    lineHeight: 1.5,
                }}>
                <Typography gutterBottom sx={{fontWeight:'bold',color: "#0b3452"}}>
                    1. Consent Flow APIs
                </Typography>

                <Typography variant="body1" paragraph>
                    The Consent Flow APIs provide detailed insights into the consent journey between FIU, AA, and FIP, 
                    tracking the status and failures across the entire consent lifecycle. These APIs help monitor events using
                    <Box component="span" fontWeight="bold"> ConsentId, ConsentHandleId,</Box>  or <Box component="span" fontWeight="bold"> UserId.</Box>         
                </Typography>
                
                <Typography sx={{marginBottom:1,fontStyle:'italic'}}>Consent Journey Events:</Typography>

                <Grid item xs={12} sx={{marginBottom:4}}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: { xs: 2, lg: 0 }
                        }}
                    > 
                        <Box
                        component="img"
                        src={ConsentFlowImage}
                        alt="Finvu Account Aggregator"
                        sx={{
                            display:'flex',
                            alignItems:'center',
                            boxShadow: '0 4px 8px rgba(0, 0, 128, 0.5)',
                            width: '100%',
                            maxWidth: { xs: 300, sm: 500 },

                            marginTop: { xs: 2, lg: 0 }
                        }}
                    />

                    </Box>
                    
                </Grid>
            </Box>


            
            <Box 
                sx={{ marginTop: 1,
                    fontWeight: 'bold',
                    fontSize: { xs: "1.2rem", sm: "1.5rem" },
                    display: 'inline-block',
                    marginBottom: 1,
                    lineHeight: 1.5,
                }}>
                <Typography gutterBottom sx={{fontWeight:'bold',color: "#0b3452",}}>
                    2. Data Flow APIs
                </Typography>

                <Typography variant="body1" paragraph>
                    The Data Flow APIs offer a detailed view of the data-fetch journey between FIU, AA,
                    and FIP, helping track the status of the data flow using
                    <Box component="span" fontWeight="bold"> SessionIds, ConsentHandleIds, ConsentIds,</Box>  or  <Box component="span" fontWeight="bold">UserIds. </Box>      
                </Typography>
                
                <Typography sx={{marginBottom:1,fontStyle:'italic'}}>Data Fetch Journey Events:</Typography>

                <Grid item xs={12} sx={{marginBottom:4}}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: { xs: 2, lg: 0 }
                        }}
                    > 
                        <Box
                        component="img"
                        src={DataFlowImage}
                        alt="Finvu Account Aggregator"
                        sx={{
                            display:'flex',
                            alignItems:'center',
                            boxShadow: '0 4px 8px rgba(0, 0, 128, 0.5)',
                            width: '100%',
                            maxWidth: { xs: 300, sm: 500 },

                            marginTop: { xs: 2, lg: 0 }
                        }}
                    />

                    </Box>
                    
                </Grid>
            </Box>
             */}
   

            <Box 
                sx={{ marginTop: 1,
                    fontWeight: 'bold',
                    fontSize: { xs: "1.2rem", sm: "1.5rem" },
                    display: 'inline-block',
                    marginBottom: .75,
                    lineHeight: 1.5,
                    paddingRight: 3
                }}>
                <Typography gutterBottom  sx={{fontWeight:'bold', fontSize: { xs: "1.2rem", sm: "1.5rem" },color: "#0b3452"}}>
                    1. FIP Latest Health Metrics API
                </Typography>

                <Typography variant="body1" gutterBottom>
                    Get real-time visibility into the health of Financial Information Providers (FIPs) 
                    with our FIP Health API. The response consists of a list of JSON objects, 
                    where each object contains health metrics for a specific FIP+Event combination. 
                </Typography>

                <Typography gutterBottom  sx={{fontWeight:'bold',color: "#0b3452", paddingTop: 3}}>
                    Key events tracked:
                </Typography>

                <HealthApiEventTable/>
                 
                <Typography sx={{fontWeight:'bold',marginBottom:1,color: "#0b3452", paddingTop: 3}}>Metric Calculation:</Typography>

                <Typography sx={{marginBottom:2,paddingLeft:2}}>
                    <BlackDot/> Each metric in the response gives the health status of the FIP for a particular event.
                </Typography>
                <Typography
                    sx={{marginBottom:2,paddingLeft:2}}
                >
                    <BlackDot/> These metrics are calculated over a 1-minute window for each FIP-Event combination. If a particular event (discovery, linking) doesn't occur for a specific FIP in the last 24 hours, the metrics for that FIP-Event combination will show as zero
                </Typography>
                <Typography
                    sx={{marginBottom:2,paddingLeft:2}}
                >
                    <BlackDot/> For example, if a User Discovery Response was received at 10:00 AM for an FIP, and no further activity occurred afterward, a request to the FIP Health API at 1:00 PM will return the metrics based on the 1-minute window between 10:00 AM and 10:01 AM.
                </Typography>
            </Box>
            

            <Box sx={{marginBottom:3}}>
                <Typography sx={{fontWeight:'bold',color: "#0b3452",textAlign:'left', paddingTop: 3}}>Request :</Typography>
                <HealthApiRequestTable/>
            </Box>

            <Box>
                <Typography sx={{fontWeight:'bold',color: "#0b3452", textAlign:'left', paddingTop: 3}}>Response :</Typography>
                <HealthApiResponseTable/>
            </Box>
            
            {/* <Box 
                sx={{ marginTop: 3,
                    fontWeight: 'bold',
                    fontSize: { xs: "1.2rem", sm: "1.5rem" },
                    display: 'inline-block',
                    marginBottom: .75,
                    lineHeight: 1.5,
                    paddingRight: 3
                }}>
                <Typography gutterBottom  sx={{fontWeight:'bold',fontSize: { xs: "1.2rem", sm: "1.5rem" },color: "#0b3452"}}>
                    FIP Success Score API
                </Typography>

                <Typography variant="body1" gutterBottom>
                    Get insights into success scores and traffic volumes of Financial Information Providers for key events in data and consent journey. 
                </Typography>

                <Typography gutterBottom  sx={{fontWeight:'bold',color: "#0b3452", paddingTop: 3}}>
                    Key events tracked:
                </Typography>

                <HealthApiEventTable/>
                 
                <Typography sx={{fontWeight:'bold',marginBottom:1,color: "#0b3452", paddingTop: 3}}>Success Score Calculation:</Typography>
                <Typography
                    sx={{marginBottom:2,paddingLeft:2}}
                >
                    <BlackDot/> We will have to provide 3 parameters, while sending request to this API: Interval (range) is Uniform interval at which counts are aggregated, Data Interval (frequency) will be up to four data intervals at which data points of success rate are to be collected, Weights (weightage) will be weights associated with Data Intervals.
                </Typography>
                <Typography
                    sx={{marginBottom:2,paddingLeft:2}}
                >
                    <BlackDot/> Success rate will be measured on aggregated counts at a specified interval size. Possible intervals are be every 5 minutes, 15 minutes or 1 hour
                </Typography>
                <Typography
                    sx={{marginBottom:2,paddingLeft:2}}
                >
                    <BlackDot/> Data point intervals will be specified in request in integers indicating the nth instance of interval from current time. For e.g., data point intervals specified for hourly frequency are 0, 12, 24 and 48 then data points collected for success rate would be from latest 1 hour, 12th hour, 24th hour and 48th hour
                </Typography>
                <Typography
                    sx={{marginBottom:2,paddingLeft:2}}
                >
                    <BlackDot/> Success score will be a weighted average calculated as: (Data point 1 * weight 1) + (Data point 2 * weight 2) + (Data point 3 * weight 3) + (Data point 4 * weight 4)
                </Typography>
                <Typography
                    sx={{marginBottom:2,paddingLeft:2}}
                >
                    <BlackDot/> For example, if aggregation interval (range) is specified as 1 hour, and data interval (frequency) specified are 0th, 12th, 24th, 48th and weightage is specified as [0.4, 0.3, 0.2, 0.1], then health score will be calculated as:
                </Typography>
            </Box> */}
            
            {/* <Box sx={{marginBottom:3}}>
                <Typography sx={{fontWeight:'bold',color: "#0b3452",textAlign:'left', paddingTop: 0}}></Typography>
                <SuccessScoreCalculationTable/>
            </Box>

            <Box sx={{marginBottom:3}}>
                <Typography sx={{fontWeight:'bold',color: "#0b3452",textAlign:'left', paddingTop: 3}}>Request :</Typography>
                <SuccessScoreRequestTable/>
            </Box>

            <Box>
                <Typography sx={{fontWeight:'bold',color: "#0b3452", textAlign:'left', paddingTop: 3}}>Response :</Typography>
                <SuccessScoreResponseTable/>
            </Box> */}
            

            {/* <Divider sx={{marginBottom:2}}></Divider>

            <Typography sx={{marginBottom:1.5,marginTop:1.5}}><Box component="span" fontWeight="bold">*Color-Codes</Box> <Box component="span" fontStyle='italic'> (Status)</Box></Typography>

            <Typography sx={{paddingLeft:2,paddingBottom:1.5}}>For Consent and Data Fetch Journey APIs:</Typography>

            <StatusTable/>

            <Typography sx={{paddingLeft:2,marginTop:1.5}}>In case of a <Box component="span" fontWeight="bold" color='#f08080'>FAILURE</Box>, the API returns the <Box component="span" fontWeight="bold">transaction ID </Box>
            and the reason for the <Box component="span" fontWeight="bold">failure.</Box></Typography>

            <Typography sx={{paddingLeft:2,marginTop:1}}><Box component="span" fontWeight="bold">Note:</Box> The DateRange for UserClientRequests should not exceed 24 hours.</Typography> */}

<Box 
                sx={{ marginTop: 1,
                    fontWeight: 'bold',
                    fontSize: { xs: "1.2rem", sm: "1.5rem" },
                    display: 'inline-block',
                    marginBottom: 5.75,
                    lineHeight: 1.5,
                    paddingTop: 4,
                    paddingRight: 3
                }}>
                <Typography gutterBottom  sx={{fontWeight:'bold', fontSize: { xs: "1.2rem", sm: "1.5rem" },color: "#0b3452"}}>
                    2. Consent Flow APIs
                </Typography>

                <Typography variant="body1" gutterBottom>
                    The Consent Flow APIs provide detailed insights into the consent journey between FIU, AA, and FIP, 
                    tracking the status and failures across the entire consent lifecycle. These APIs help monitor events using
                    <Box component="span" fontWeight="bold"> ConsentId, ConsentHandleId,</Box>  or <Box component="span" fontWeight="bold"> UserId.</Box>         
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Following is consent journey flow between FIU-AA-FIP:
                </Typography>

                <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: { xs: 2, lg: 0 }
                        }}
                    > 
                        <Box
                        component="img"
                        src={ConsentFlowImage}
                        alt="Finvu Account Aggregator"
                        sx={{
                            display:'flex',
                            alignItems:'center',
                            width: '100%',
                            maxWidth: { xs: 500, sm: 700 },

                            marginTop: { xs: 2, lg: 2 }
                        }}
                    />

                    </Box>

                <Typography gutterBottom  sx={{fontWeight:'bold',color: "#0b3452", paddingTop: 3}}>
                    Events tracked:
                </Typography>

                <ConsentApiEventTable/>
                
                 
                <Typography sx={{fontWeight:'bold',marginBottom:1,color: "#0b3452", paddingTop: 3}}>Apis Information:</Typography>

                <Typography sx={{marginBottom:2,paddingLeft:2}}>
                    There are 2 APIs for consent flow based on the parameters we have.
                    
                </Typography>
                <Typography gutterBottom  sx={{fontWeight:'bold',color: "#0b3452", paddingTop: 3}}>
                    1. /v1/consentHandleId/{'{consentHandleId}'}
                </Typography>
                <Typography sx={{marginBottom:2,paddingLeft:2}}>
                <BlackDot/> Paramaters: <b> consentHandleId </b>
                </Typography>
                <Typography sx={{marginBottom:2,paddingLeft:2}}>
                <BlackDot/> Gives information about the consent journey
                </Typography>
 {/*               </Typography>
                 <Typography sx={{marginBottom:2,paddingLeft:2}}>
                <BlackDot/> This api response has <b>list of all events tracked</b> containing request-reponse and of each event between FIU-AA-FIP
                     <b>UserId, Status</b> as GREEN 🟩 / AMBER 🟧 / RED 🟥, 
                    <b>Description</b> along with <b>txn id</b> of the complete consent jounery    
                </Typography>
                <Typography sx={{marginBottom:2,paddingLeft:2}}>
                <BlackDot/> Status here are  GREEN 🟩 which represents sucessfull journey, AMBER 🟧 represents partial completion / RED 🟥 represents failure  
                </Typography> 
                <Typography sx={{marginBottom:2,paddingLeft:2}}>
*/}
<Typography sx={{marginBottom:2,paddingLeft:2}}>
        <BlackDot/> The response includes a hierarchical structure of events and nodes associated with the consent journey. The key elements are:
        <ul>
            <li><b>consentHandleId:</b> The unique ID representing the consent journey.</li>
            <li><b>userId:</b> The user associated with the consent journey.</li>
            <li><b>status:</b> The overall status of the journey: GREEN 🟩 (Successful), AMBER 🟧 (Partial), RED 🟥 (Failure).</li>
            <li><b>description:</b> A summary of the journey's outcome.</li>
            <li><b>txnId:</b> A unique transaction ID of failed/partially failed event in the journey or txn id of last occured event in case of successful journey.</li>
        </ul>
    </Typography>
    <Typography sx={{marginBottom:2,paddingLeft:2}}>
        <BlackDot/> The node object represents a sequence of events (NODE or EVENT):
        <ul>
            <li><b>seqNum:</b> The sequence number of the event in the consent journey.</li>
            <li><b>nodeType:</b> Indicates whether the event is a NODE (parent node) or EVENT (individual action).</li>
            <li><b>request/response:</b> Includes details such as name, description, timestamp, status (GREEN/AMBER/RED), and statusCode (e.g., 200 for success).</li>
        </ul>
    </Typography>
                <Typography gutterBottom  sx={{fontWeight:'bold',color: "#0b3452", paddingTop: 3}}>
                    2. /v1/consentHandleIds
                </Typography>
                <Typography sx={{marginBottom:2,paddingLeft:2}}>
                <BlackDot/> Paramaters: <b> UserId + DateRange(last 24 hrs allowed only) <b>/</b> consentId </b>
                </Typography>
                <Typography sx={{marginBottom:2,paddingLeft:2}}>
                <BlackDot/> Gives list of consentHandleIds with the event time of each consentHandleId
                </Typography>
                <Typography sx={{marginBottom:2,paddingLeft:2}}>
                <BlackDot/> These consentHandleIds can be used to check consent journey by help of /consentHandleId api
                </Typography>
            </Box>
            <Box 
                sx={{ marginTop: 1,
                    fontWeight: 'bold',
                    fontSize: { xs: "1.2rem", sm: "1.5rem" },
                    display: 'inline-block',
                    marginBottom: 5.75,
                    lineHeight: 1.5,
                    paddingTop: 0,
                    paddingRight: 3
                }}>
                <Typography gutterBottom  sx={{fontWeight:'bold', fontSize: { xs: "1.2rem", sm: "1.5rem" },color: "#0b3452"}}>
                    3. Data Flow APIs
                </Typography>

                <Typography variant="body1" gutterBottom>
                    The Data Flow APIs provide detailed insights into the data journey between FIU, AA, and FIP, 
                    tracking the status and failures across the entire FI Fetch lifecycle. These APIs help monitor events using
                    <Box component="span" fontWeight="bold"> SessionId,ConsentId, ConsentHandleId,</Box>  or <Box component="span" fontWeight="bold"> UserId.</Box>         
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Following is data journey flow between FIU-AA-FIP:
                </Typography>

                <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: { xs: 2, lg: 0 }
                        }}
                    > 
                        <Box
                        component="img"
                        src={DataFlowImage}
                        alt="Finvu Account Aggregator"
                        sx={{
                            display:'flex',
                            alignItems:'center',
                            width: '100%',
                            maxWidth: { xs: 300, sm: 500 },

                            marginTop: { xs: 2, lg: 2 }
                        }}
                    />

                    </Box>

                <Typography gutterBottom  sx={{fontWeight:'bold',color: "#0b3452", paddingTop: 3}}>
                    Events tracked:
                </Typography>

                <DataApiEventTable/>
                
                 
                <Typography sx={{fontWeight:'bold',marginBottom:1,color: "#0b3452", paddingTop: 3}}>Apis Information:</Typography>

                <Typography sx={{marginBottom:2,paddingLeft:2}}>
                    There are 2 APIs for data flow based on the parameters we have.
                    
                </Typography>
                <Typography gutterBottom  sx={{fontWeight:'bold',color: "#0b3452", paddingTop: 3}}>
                    1. /v1/sessionId/{'{sessionId}'}
                </Typography>
                <Typography sx={{marginBottom:2,paddingLeft:2}}>
                <BlackDot/> Paramaters: <b> sessionId </b>
                </Typography>
                <Typography sx={{marginBottom:2,paddingLeft:2}}>
                <BlackDot/> Gives information about the FI fetch journey
                </Typography>
 {/*               </Typography>
                 <Typography sx={{marginBottom:2,paddingLeft:2}}>
                <BlackDot/> This api response has <b>list of all events tracked</b> containing request-reponse and of each event between FIU-AA-FIP
                     <b>UserId, Status</b> as GREEN 🟩 / AMBER 🟧 / RED 🟥, 
                    <b>Description</b> along with <b>txn id</b> of the complete consent jounery    
                </Typography>
                <Typography sx={{marginBottom:2,paddingLeft:2}}>
                <BlackDot/> Status here are  GREEN 🟩 which represents sucessfull journey, AMBER 🟧 represents partial completion / RED 🟥 represents failure  
                </Typography> 
                <Typography sx={{marginBottom:2,paddingLeft:2}}>
*/}
<Typography sx={{marginBottom:2,paddingLeft:2}}>
        <BlackDot/> The response includes a hierarchical structure of events and nodes associated with the data journey. The key elements are:
        <ul>
            <li><b>sessionId:</b> The unique ID representing the FI fetch journey.</li>
            <li><b>userId:</b> The user associated with the fi fetch journey.</li>
            <li><b>status:</b> The overall status of the journey: GREEN 🟩 (Successful), AMBER 🟧 (Partial), RED 🟥 (Failure).</li>
            <li><b>description:</b> A summary of the journey's outcome.</li>
            <li><b>txnId:</b> A unique transaction ID of failed/partially failed event in the journey or txn id of last occured event in case of successful journey.</li>
        </ul>
    </Typography>
    <Typography sx={{marginBottom:2,paddingLeft:2}}>
        <BlackDot/> The node object represents a sequence of events (NODE or EVENT):
        <ul>
            <li><b>seqNum:</b> The sequence number of the event in the data journey.</li>
            <li><b>nodeType:</b> Indicates whether the event is a NODE (parent node) or EVENT (individual action).</li>
            <li><b>request/response:</b> Includes details such as name, description, timestamp, status (GREEN/AMBER/RED), and statusCode (e.g., 200 for success).</li>
        </ul>
    </Typography>
                <Typography gutterBottom  sx={{fontWeight:'bold',color: "#0b3452", paddingTop: 3}}>
                    2. /v1/sessionIds
                </Typography>
                <Typography sx={{marginBottom:2,paddingLeft:2}}>
                <BlackDot/> Paramaters: <b> UserId + DateRange(last 24 hrs allowed only) <b>/</b> consentId <b>/</b> consentHandleId </b>
                </Typography>
                <Typography sx={{marginBottom:2,paddingLeft:2}}>
                <BlackDot/> Gives list of sessionIds with the event time of each sessionId
                </Typography>
                <Typography sx={{marginBottom:2,paddingLeft:2}}>
                <BlackDot/> These sessionIds can be used to check data journey by help of /sessionId api
                </Typography>
            </Box>
            <Box 
                sx={{ marginTop: 1,
                    fontWeight: 'bold',
                    fontSize: { xs: "1.2rem", sm: "1.5rem" },
                    display: 'inline-block',
                    marginBottom: 5.75,
                    lineHeight: 1.5,
                    paddingTop: 0,
                    paddingRight: 3
                }}>
                <Typography gutterBottom  sx={{fontWeight:'bold', fontSize: { xs: "1.2rem", sm: "1.5rem" },color: "#0b3452"}}>
                    4. User Flow APIs
                </Typography>

                <Typography variant="body1" gutterBottom>
                    The User Flow APIs provide detailed insights into the user journey between FIU and AA,
                    tracking the number of events and how the user behaviour was. These APIs help monitor events using
                    <Box component="span" fontWeight="bold"> ConnectionKey, ConsentHandleId,</Box>  or <Box component="span" fontWeight="bold"> UserId.</Box>         
                </Typography>
                 
                <Typography sx={{fontWeight:'bold',marginBottom:1,color: "#0b3452", paddingTop: 3}}>Apis Information:</Typography>

                <Typography sx={{marginBottom:2,paddingLeft:2}}>
                    There are 2 APIs for user flow based on the parameters we have.
                    
                </Typography>
                <Typography gutterBottom  sx={{fontWeight:'bold',color: "#0b3452", paddingTop: 3}}>
                    1. /v1/connectionKey/{'{connectionKey}'}
                </Typography>
                <Typography sx={{marginBottom:2,paddingLeft:2}}>
                <BlackDot/> Paramaters: <b> connectionKey </b>
                </Typography>
                <Typography sx={{marginBottom:2,paddingLeft:2}}>
                <BlackDot/> Gives information about the number of events the user has performed
                </Typography>
 {/*               </Typography>
                 <Typography sx={{marginBottom:2,paddingLeft:2}}>
                <BlackDot/> This api response has <b>list of all events tracked</b> containing request-reponse and of each event between FIU-AA-FIP
                     <b>UserId, Status</b> as GREEN 🟩 / AMBER 🟧 / RED 🟥, 
                    <b>Description</b> along with <b>txn id</b> of the complete consent jounery    
                </Typography>
                <Typography sx={{marginBottom:2,paddingLeft:2}}>
                <BlackDot/> Status here are  GREEN 🟩 which represents sucessfull journey, AMBER 🟧 represents partial completion / RED 🟥 represents failure  
                </Typography> 
                <Typography sx={{marginBottom:2,paddingLeft:2}}>
*/}
<Typography sx={{marginBottom:2,paddingLeft:2}}>
        <BlackDot/> The response includes list of events associated with the user journey. The key elements are:
        <ul>
            <li><b>eventName:</b> Name of event perfomed by user(login,discovery,linking,view consent,consent action,etc).</li>
            <li><b>txnId:</b> A unique transaction ID of the event in the journey.</li>
        </ul>
    </Typography>
    
                <Typography gutterBottom  sx={{fontWeight:'bold',color: "#0b3452", paddingTop: 3}}>
                    2. /v1/connectionKeys
                </Typography>
                <Typography sx={{marginBottom:2,paddingLeft:2}}>
                <BlackDot/> Paramaters: <b> UserId + DateRange <b>/</b> consentHandleId </b>
                </Typography>
                <Typography sx={{marginBottom:2,paddingLeft:2}}>
                <BlackDot/> Gives list of connectionKeys with the event time of each connectionKeys
                </Typography>
                <Typography sx={{marginBottom:2,paddingLeft:2}}>
                <BlackDot/> These connectionKeys can be used to check user journey by help of /connectionKey api
                </Typography>
            </Box>
        </Box>
        
        <Box padding={1}></Box>
            {/* <Select
                value={selectedUrl}
                onChange={handleChange}
                displayEmpty
                sx={{ marginBottom: 2, minWidth: 300 ,marginLeft:4}}
            >
                <MenuItem value="https://fastrack.stage.finvu.in/v3/api-docs">FasTrack UAT URLs</MenuItem>
                <MenuItem value="https://fastrack.finvu.in/v3/api-docs">FasTrack PROD URLs</MenuItem>
            </Select> */}

            <Box sx={{ 
                bgcolor: '#f5f5f5', 
                p: 2, 
                borderRadius: 1, 
                mb: 3,
                mx: 4,
                border: '1px solid #e0e0e0'
            }}>
                <Typography sx={{ color: '#0b3452', fontWeight: 'bold' }}>
                    Note:
                </Typography>
                <Typography sx={{ color: '#555' }}>
                    You need client_api_key from Sahamati to try out these endpoints. Please refer to{' '}
                    <Link 
                        to="/api-integration#client-api-token"
                        style={{
                            color: '#0b3452',
                            textDecoration: 'none',
                            fontWeight: 'bold'
                        }}
                        onMouseEnter={(e) => e.currentTarget.style.textDecoration = 'underline'}
                        onMouseLeave={(e) => e.currentTarget.style.textDecoration = 'none'}
                    >
                        "Getting a Client API Token"
                    </Link>
                    {' '}in the Backend Integration section for detailed instructions.
                </Typography>
            </Box>

            <RapiDocReact
                spec-url={selectedUrl}
                show-info={false}
                show-header={false}
                render-style="view"
                primary-color="#0b3452"
                bg-color="#FAFBFC"
                // allow-try={false}
                // allow-authentication={false}
                schema-style="table"
                font-size="large"
                text-color="#000000"
                regular-font='"Roboto","Helvetica","Arial",sans-serif'
                mono-font='"Roboto","Helvetica","Arial",sans-serif'
            />

        <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            mt: 6,
            mb: 4,
            borderTop: '1px solid #e0e0e0',
            pt: 4
        }}>
            <Button 
                startIcon={<ArrowBackIcon />}
                onClick={() => {
                    navigate('/split-consent-architecture');
                    setTimeout(() => {
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth'
                        });
                    }, 100);
                }}
                sx={{ color: '#0b3452' }}
            >
                Split Consent
            </Button>
            <Box flexGrow={1} />
            <Button 
                endIcon={<ArrowForwardIcon />}
                onClick={() => {
                    navigate('/lens');
                    setTimeout(() => {
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth'
                        });
                    }, 100);
                }}
                sx={{ color: '#0b3452' }}
            >
                Lens
            </Button>
        </Box>
    </>
});

export default WealthScapePage;
